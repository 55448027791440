<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcGideonCardRestockingVisit }}</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcGideonCardRestockingVisit }}</h1>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <i class="mr-3">
                <iMapPoint />
              </i>
              <h6>{{ facility.org_name }}</h6>
            </div>
            <div class="address mb-3">
              <span v-if="!!facility">{{ facility.address_line_1 }}</span>
              <br />
              <span v-if="!!facility.address_line_2">{{ facility.address_line_2 }}<br /></span>
              <span v-if="!!facility.address_line_3">{{ facility.address_line_3 }}<br /></span>
              <span v-if="!!facility">{{ facility.city }}, {{ facility.state_postal_code }}</span>
              <br />
              <span v-if="!!facility">{{ facility.address_cnt_name }}</span>
            </div>
            <div class="d-flex mb-3">
              <i class="mr-3">
                <iCalendar />
              </i>
              <p>{{ formattedStartDate }}</p>
            </div>
          </b-col>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <span class="label font-style-1 mr-2">{{ translations.tcContactedBy }}:</span>
            </div>
            <div class="mb-3">
              <span style="width: 100%"> {{ coordinator.formal_name }}</span>
              <br />
              <span style="width: 100%">{{ coordinator.phone }}</span>
              <br />
              <span style="width: 100%">{{ coordinator.email }}</span>
              <br />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex">
              <span class="label font-style-1 mr-2">{{ translations.tcStatus }}:</span>
              <span class="value font-weight-bold lh44">
                {{ status }}
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcProducts }} ({{ availableList.length }})</h2>
        </header>
        <div class="body">
          <div v-for="(visit, index) in availableList" :key="index" :obj="visit">
            <div class="g-row mb-0 mb-sm-3">
              <div class="col-md-9 mb-3 mb-sm-0 d-flex align-items-center">
                <h4>{{ visit.product_name }}</h4>
              </div>
              <div class="col-md-3 mb-3 mb-sm-0 d-flex align-items-center" style="text-align: right">
                <h4 :id="'typenumber' + index" class="flex-0 a-ml-3" type="number">
                  {{ translations.tcPlaced }} {{ visit.plc_quantity_placed }}
                </h4>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <p>{{ meetingNote }}</p>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button
              v-if="iCanSee(secured_restocking_visit_edit_controls.edit_gideoncard_restocking_visit_button)"
              variant="primary"
              @click="handleEditClick"
              class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
              >{{ translations.tcEdit }}</b-button
            >
            <b-button
              v-if="iCanSee(secured_restocking_visit_delete_controls.delete_gideoncard_restocking_visit_button)"
              variant="secondary"
              @click="handleDeleteClick"
              class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
              >{{ translations.tcDelete }}</b-button
            >
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'restocking-visit-details',
  mixins: [translationMixin],
  data() {
    return {
      formattedStartDate: '',
      secured_restocking_visit_delete_controls: {
        delete_gideoncard_restocking_visit_button: 'f8d26c44-16fa-4a04-b621-b97872c782e4',
      },
      secured_restocking_visit_edit_controls: {
        edit_gideoncard_restocking_visit_button: '7d2ed082-cf7f-489c-b298-8fe90a60073c',
      },
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      deleteVideoPresentation: 'card/deleteVideoPresentation',
      loadProfile: 'card/getProfile',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      retrieveRestockingVisitDetails: 'card/retrieveRestockingVisitDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
    }),
    async handleEditClick() {
      await this.setSelectedFacilityKey(this.facility.gcf_org_key)
      this.$router.push('/programs/gc/events/card_restocking_visit_add')
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        confirmButtonText: this.translations.tcOk,
        text: this.translations.tcDeleteVisitConfirmation,
      }).then((result) => {
        if (result.value) {
          try {
            Promise.all([
              this.setLoadingStatus(true),
              this.deleteVideoPresentation({ mtg_key: this.selectedVisitKey, ind_key: this.userId }),
            ]).then(() => {
              this.$router.push('/programs/gc/profile/card_restocking_visits_list')
            })
          } catch (e) {
            console.error(e)
          } finally {
            this.setLoadingStatus(false)
          }
        }
      })
    },
    async handleCancelClick() {
      await this.setSelectedFacilityKey(this.facility.gcf_org_key)
      this.$router.push('/programs/gc/profile/card_restocking_visits_list')
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.retrieveMeetingTypes(),
        await this.retrieveRestockingVisitDetails(this.selectedVisitKey),
      ]).then(() => {
        this.setSelectedFacilityKey(this.restockingVisitDetails.facility.gcf_org_key)
        this.loadProfile(this.userSelectedFacilityKey)
        this.formattedStartDate = this.returnFormattedDate(this.restockingVisitDetails.meeting.mtg_start_date)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      meetingTypes: 'card/getMeetingTypes',
      prefCulture: 'user/userPreferredCulture',
      restockingVisitDetails: 'card/getRestockingVisitDetails',
      selectedVisitKey: 'churchMinistry/selected_visit_key',
      userId: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
    facility() {
      return (
        this.restockingVisitDetails.facility || {
          contact_name: '',
          org_name: '',
          address_line_1: '',
        }
      )
    },
    status() {
      if (
        !this.restockingVisitDetails ||
        !this.restockingVisitDetails.meeting ||
        !this.restockingVisitDetails.meeting.mtg_mts_key
      ) {
        return ''
      }

      let today = new Date()
      let visdate = new Date(this.meetingDate)
      if (visdate > today) {
        let status = this.meetingTypes.find((item) => item.description.toLowerCase().indexOf('pending') > -1)
        return this.translations.tcPending
      } else {
        let status = this.meetingTypes.find((item) => item.description.toLowerCase().indexOf('complete') > -1)
        return this.translations.tcComplete
      }
    },
    presenterName() {
      if (
        !this.restockingVisitDetails ||
        !this.restockingVisitDetails.presentationIndividual ||
        !this.restockingVisitDetails.presentationIndividual.ind_first_name
      ) {
        return ''
      }
      return `${this.restockingVisitDetails.presentationIndividual.ind_first_name} ${this.restockingVisitDetails.presentationIndividual.ind_last_name}`
    },
    coordinator() {
      return !!this.restockingVisitDetails && !!this.restockingVisitDetails.coordinator
        ? {
            formal_name: this.restockingVisitDetails.coordinator.formal_name,
            email: this.restockingVisitDetails.coordinator.email,
            phone: this.restockingVisitDetails.coordinator.phone,
          }
        : ''
    },
    meetingNote() {
      return !!this.restockingVisitDetails && !!this.restockingVisitDetails.meeting
        ? this.restockingVisitDetails.meeting.mtg_note
        : ''
    },
    meetingDate() {
      return !!this.restockingVisitDetails &&
        !!this.restockingVisitDetails.meeting &&
        !!this.restockingVisitDetails.meeting.mtg_start_date
        ? new Date(this.restockingVisitDetails.meeting.mtg_start_date).toDateString()
        : ''
    },
    availableList() {
      return !!this.restockingVisitDetails && !!this.restockingVisitDetails.list
        ? this.restockingVisitDetails.list
            .filter((data) => data.plc_quantity_placed != null)
            .map((data) => {
              return data
            })
        : []
    },
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.lh44 {
  line-height: 44px;
}
h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}
.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 46px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}
.a-ml-3 {
  margin-left: 1rem !important;
  @media (min-width: 360px) and (max-width: 640px) {
    margin-left: 0rem !important;
  }
}
.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}
</style>
